import React, { useState, useEffect } from 'react'
import { database, auth } from './firebase'
import { useNavigate } from 'react-router-dom'
import { ref, push, set, get } from 'firebase/database'
import { GoogleButton } from 'react-google-button'
import {
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged
} from 'firebase/auth'
import { GoogleAuthProvider } from 'firebase/auth'
import { signInWithPopup } from 'firebase/auth'
import { SHA256 } from 'crypto-js'

const provider = new GoogleAuthProvider()

const NewMeeting = () => {
  const navigate = useNavigate()
  const [meetingId, setMeetingId] = useState(null)
  const [meetingNotes, setMeetingNotes] = useState('')
  const [meetingList, setMeetingList] = useState([])
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')

  const createNewMeeting = () => {
    const newMeetingRef = ref(database, 'meetings')
    const newMeetingId = push(newMeetingRef, meetingId).key
    const meeting_ref = ref(database, `meetings/${newMeetingId}`)
    set(meeting_ref, {
      notes: meetingNotes,
      createdAt: Date.now()
    })
    setMeetingId(newMeetingId)
    navigate(`/meeting/${newMeetingId}`)
  }

  const getUserMeetings = async () => {
    const hashedEmail = SHA256(userEmail).toString()
    const meetingsRef = ref(database, `users/${hashedEmail}`)
    const snapshot = await get(meetingsRef)
    const meetings = snapshot.val()
    const meetingIds = Object.keys(meetings)

    // for (const meetingId in meetings) {
    //   const users = meetings[meetingId].users
    //   console.log('users', users)
    //   if (users && Object.keys(users).includes(hashedEmail)) {
    //     meetingIds.push(meetingId)
    //   }
    // }
    return meetingIds
  }

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        const userName_ = user.displayName || user.email
        if (user && userName === '') {
          setUserName(userName_)
          setUserEmail(user.email)
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.User
          // console.log(user.displayName)
          // console.log(user.email)
        } else {
          // User is signed out
          console.log('User is signed out')
          console.log(user.email)
        }
      }
    })
  }, [userName]) // This effect runs whenever userName changes

  const signInWithGoogle = () => {
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithPopup(auth, provider)
        .then(result => {
          // This gives you a Google Access Token. You can use it to access Google APIs.
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          // const token = credential.accessToken
          // The signed-in user info.
          const user = result.user
          // Use the display name if available; otherwise, fall back to the email.
          const userName_ = user.displayName || user.email
          // You can use the 'user' object here to get the authenticated user name.
          console.log(user.email)
          setUserName(userName_)
          setUserEmail(user.email)
        })
        .catch(error => {
          // Handle Errors here.
          const errorCode = error.code
          const errorMessage = error.message
          console.error(`Error: ${errorCode}, ${errorMessage}`)
        })
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f2f2f2',
        position: 'relative' // Added to position the button absolutely within this container
      }}
    >
      {!userName && (
        <div>
          <h3
            style={{
              display: 'flex',
              marginTop: '35px',
              color: 'gray',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '10px',
              justifyContent: 'center'
            }}
          >
            'Sign in to create event'
          </h3>
          <GoogleButton
            onClick={signInWithGoogle}
            style={{ marginBottom: '20px' }}
          />
        </div>
      )}

      <div>
        {userEmail && (
          <button
            style={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              backgroundColor: '#68a0cf',
              color: '#fff',
              padding: '5px 10px',
              border: 'none',
              fontSize: '15px',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
            onClick={async () => {
              const userMeetings = await getUserMeetings({ userEmail })
              if (userMeetings.length > 0) {
                const meetingListItems = userMeetings.map(async meetingId => {
                  const meetingRef = ref(database, `meetings/${meetingId}`)
                  const snapshot = await get(meetingRef)
                  const meetingData = snapshot.val()
                  console.log(meetingData.notes)
                  return (
                    <li
                      key={meetingId}
                      style={{
                        cursor: 'pointer',
                        listStyleType: 'none',
                        marginBottom: '5px'
                      }}
                      onClick={() => navigate(`/meeting/${meetingId}`)}
                    >
                      {meetingData.notes}
                    </li>
                  )
                })
                Promise.all(meetingListItems).then(items =>
                  setMeetingList(items)
                )
              } else {
                setMeetingList([])
              }
            }}
          >
            Show My Other Events
          </button>
        )}
        {meetingList.length > 0 && (
          <select
            onChange={e => navigate(`/meeting/${e.target.value}`)}
            style={{
              position: 'absolute',
              top: '50px',
              left: '10px',
              backgroundColor: '#fff',
              padding: '10px',
              borderRadius: '5px',
              fontSize: '15px',
              maxHeight: '50vh',
              overflowY: 'auto',
              width: '200px' // Adjust width as needed
            }}
            value=''
          >
            <option value='' disabled>
              Select an event
            </option>
            {meetingList.map((meeting, index) => (
              <option key={index} value={meeting.key}>
                {meeting.props.children}
              </option>
            ))}
          </select>
        )}
      </div>
      <div>
        <img src={process.env.PUBLIC_URL + '../logo192.png'} alt='Logo' />
      </div>

      {userEmail && (
        <>
          <h2>Event Info</h2>
          <textarea
            value={meetingNotes}
            onChange={e => setMeetingNotes(e.target.value)}
            placeholder='Tell them what this event is about and at what time and where. You will specify the date in the next step.'
            rows='3'
            style={{
              borderRadius: '10px',
              padding: '10px',
              width: window.innerWidth < 500 ? '90%' : '50%',
              marginBottom: '10px',
              fontSize: '15px',
              boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)'
            }}
          />

          <br />

          <button
            style={{
              backgroundColor: '#68a0cf',
              color: '#fff',
              padding: '10px 20px',
              border: 'none',
              fontSize: '20px',
              borderRadius: '30px'
            }}
            onClick={createNewMeeting}
          >
            Create Event
          </button>
        </>
      )}
      {meetingId && <p>Meeting ID: {meetingId}</p>}
    </div>
  )
}

export default NewMeeting
