import CryptoJS from 'crypto-js'

const privateKey = process.env.REACT_APP_enc_key

function encryptWithPrivateKey (text) {
  const encrypted = CryptoJS.AES.encrypt(text, privateKey).toString()
  return encrypted
}

function decryptWithPrivateKey (text) {
  const bytes = CryptoJS.AES.decrypt(text, privateKey)
  const decrypted = bytes.toString(CryptoJS.enc.Utf8)
  return decrypted
}

export { encryptWithPrivateKey, decryptWithPrivateKey }
