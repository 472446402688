import React, { useState, useEffect } from 'react'
import { database } from './firebase'
import { useParams } from 'react-router-dom'
import { ref, push, get } from 'firebase/database'

function capitalizeFirstLetter (str) {
  return str.replace(/\b\w/g, char => char.toUpperCase())
}

const Meeting = () => {
  const { meetingId } = useParams()
  const [timeSlots, setTimeSlots] = useState([])
  const [newTimeSlot, setNewTimeSlot] = useState('')

  useEffect(() => {
    // const meetingRef = database.ref(`meetings/${meetingId}`);
    const meetingRef = ref(database, `meetings/${meetingId}`)

    meetingRef.on('value', snapshot => {
      const slots = snapshot.val() ? Object.values(snapshot.val()) : []
      setTimeSlots(slots)
    })
  }, [meetingId])

  const handleSubmit = () => {
    const meetingRef = ref(database, `meetings/${meetingId}`)
    push(meetingRef, {
      timeSlots: newTimeSlot
    })

    setNewTimeSlot('')
  }

  const getUserMeetings = async userName => {
    const userMeetings = []
    const meetingsRef = ref(database, 'meetings')
    try {
      const snapshot = await get(meetingsRef)
      const meetings = snapshot.val()
      for (const meetingId in meetings) {
        const users = meetings[meetingId].users
        if (
          users &&
          Object.keys(users).includes(capitalizeFirstLetter(userName))
        ) {
          userMeetings.push(meetingId)
        }
      }
      console.log(`Meetings for ${userName}:`, userMeetings)
      return userMeetings
    } catch (error) {
      console.error(`Error fetching meetings for ${userName}:`, error)
      return []
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <button
        style={{ position: 'absolute', top: 0, left: 0 }}
        onClick={() => getUserMeetings('Ramin Rezaiifar')}
      >
        Get User Meetings
      </button>
      <h2>Meeting ID: {meetingId}</h2>
      {timeSlots.map((slot, index) => (
        <div key={index}>{slot.timeSlots}</div>
      ))}
      <textarea
        value={newTimeSlot}
        onChange={e => setNewTimeSlot(e.target.value)}
        placeholder='Enter your preferred dates and times'
      />
      <button onClick={handleSubmit}>Submit</button>
    </div>
  )
}

export default Meeting
