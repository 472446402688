import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NewMeeting from './NewMeeting';
import FindCommonTime from './FindCommonTime';
import Meeting from './Meeting';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<NewMeeting />} />
        <Route path="/meeting/:meetingId" element={<FindCommonTime />} />
        <Route path="/meeting/:meetingId" element={<Meeting />} />
      </Routes>
    </Router>
  );
};

export default App;
