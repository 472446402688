// Function to call the OpenAI API
const callOpenAI = async userString => {
  // alert(userString)
  const apiEndpoint = 'https://api.openai.com/v1/chat/completions'
  const apiKey = process.env.REACT_APP_openai_key

  const systemRole = `
        You are a smart scheduling program designed to arrange meeting schedules for a group of people. You will be presented with a list of individuals and their respective preferred date or date ranges for a meeting. Your task is to analyze the proposed schedules of all individuals to find a common date or time slot that fits the maximum number of people's schedules.                                                                                                                                     
        if there is only one person, return their preferred date or date range.
        For each individual, cross-check their preferred dates with the suggested meeting date. If the suggested date doesn't match with an individual's preferred dates, you will add that individual's name to a list named "Scheduling conflicts". If the suggested date aligns with an individual's preferences, no action is needed for that individual.                                                                                                                                           
  
        Your output should be a structured JSON object, without any extra characters or strings. The JSON object should have four fields: 
        "Suggested schedule" (a date or time slot that caters to the majority of participants), 
        "Scheduling conflicts" (a list of names of those individuals whose schedules conflict with the proposed meeting time), 
        "Suggested schedule month" (the number representing the month of the proposed meeting date), and 
        "Suggested schedule date" (the day of the month of the proposed meeting date).                                                         
        `
  try {
    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: 'gpt-4-0125-preview',
        // model: 'gpt-3.5-turbo',
        response_format: { type: 'json_object' },
        messages: [
          {
            role: 'system',
            content: systemRole
          },
          {
            role: 'user',
            content: userString
          }
        ]
      })
    })

    if (!response.ok) {
      console.log(response.error)
      throw new Error('Network response was not ok')
    }

    const data = await response.json()
    const chat_response = data['choices'][0]['message']['content']
      .replace(/```json/g, '')
      .replace(/```/g, '')

    console.log('** chat_response: ', chat_response)
    const jsonResponse = JSON.parse(chat_response)
    console.log('** json_response: ', JSON.stringify(jsonResponse))

    return jsonResponse
  } catch (error) {
    console.error('There was an error with the OpenAI API request:', error)
    return null
  }
}

export default callOpenAI
